.cycle-text {
  position: relative;
  width: 70vw;
  height: 50px;
  overflow: hidden;
  margin-top: 50px;
  color: white;
  padding-bottom: 10em;
}

.text {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 2em;
  text-align: center;
  line-height: 30px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.text.active {
  opacity: 1;
  transition-delay: 1s; /* Delay the fade-in transition */
}

.fade-in {
  opacity: 1 !important;
  transition-delay: 0s; /* No delay for fade-in */
}

@media (max-width: 960px) and (orientation: landscape) {
  .landscapeMobile {
    margin-top: 4em;
    row-gap: 1em !important;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  #landscapeFont {
    font-size: 20px !important;
  }
}

@media (max-width: 960px) and (orientation: landscape) {
  #landscapeFontSmol {
    font-size: 14px !important;
  }
}